import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { getAllEvaUsers } from 'services/userServices';
// import { getDepartmentOtionList } from 'services/customer-service/scActionPlanService';
import UsersInCellWidget from 'components/common/users/UsersInCellWidget';
import moment from 'moment';

const MeetingTable = ({
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage,
  onPageSizeChange
}) => {
  const [users, setUsers] = useState([]);
  const isMounted = useRef(null);
  // const [dataDepartments, SetDataDepartments] = useState([]);

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();
    getAllEvaUsers(controller.signal)
      .then(res => {
        if (!isMounted.current) return null;

        if (res?.length) setUsers(res);
      })
      .catch(err => {
        console.log('Error in user fetch', err);
      });
    // const fetchDepart = async signal => {
    //   const resDepart = await getDepartmentOtionList(signal);
    //   SetDataDepartments(
    //     resDepart?.map(x => {
    //       return {
    //         ...x,
    //         value: x.id,
    //         label: x.name
    //       };
    //     })
    //   );
    // };

    // fetchDepart(controller.signal);
    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, []);

  const columns = [
    {
      accessor: 'id',
      Header: 'ID',
      isVisible: false
    },
    {
      accessor: 'title',
      Header: 'Title',
      Cell: rowData => {
        const { title, id } = rowData.row.original;

        return (
          <Link to={`/customer-service/meetings/${id}`}>

          {/* <Link to={`/meeting-management/${id}/customer-focus-dashboard`}> */}
            <Flex alignItems="center">
              <div className="flex-1">
                <h5 style={{ color: '#0052CC' }} className="mb-0 fs--1">
                  {title}
                </h5>
              </div>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'venue',
      Header: 'Venue'
    },
    {
      accessor: 'meetingLink',
      Header: 'Link to Dashboard',
      Cell: rowData => {
        const { meetingLink, id } = rowData.row.original;

        return (
          <Link to={`/meeting-management/${id}/customer-focus-dashboard`}>
            <Flex alignItems="center">
              <div className="flex-1 wrap-content">
                <h5
                  style={{
                    color: '#0052CC',
                    // wordBreak: 'break-word !importance',
                    // overflowWrap: 'break-word !importance',
                    // width:'200px'
                  }}
                  className="mb-0 fs--1"
                >
                  {truncateString(meetingLink,50)}
                </h5>
              </div>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'start',
      Header: 'Date',
      Cell: rowData => {
        const { start } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">
                {start && moment.utc(start).local().format('DD/MM/YYYY')}
              </h5>
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'reOccurent',
      Header: 'Re-occurent'
    },
    {
      accessor: 'createdBy',
      Header: 'Created By',
      Cell: rowData => {
        const { createdBy } = rowData.row.original;
        return (
          <UsersInCellWidget usersText={createdBy?.toString()} users={users} />
        );
      }
    },
    {
      accessor: 'managedBy',
      Header: 'Managed By',
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        const { managedBy } = rowData.row.original;
        return (
          <UsersInCellWidget usersText={managedBy?.toString()} users={users} />
        );
      }
    },
    {
      accessor: 'participants',
      Header: 'Attendees',
      headerProps: { style: { minWidth: '10px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        const { participants } = rowData.row.original;
        return (
          <UsersInCellWidget
            usersText={participants?.toString()}
            users={users}
          />
        );
      }
    },
    {
      accessor: 'description',
      Header: 'Objective'
    }
  ];

  return (
    <>
      <PartialTable
        data={data}
        columns={columns}
        paging={paging}
        total={total}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        onPageSizeChange={onPageSizeChange}
        textAlign="center"
      />
    </>
  );
};

MeetingTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func,
  onPageSizeChange: PropTypes.func
};

export default MeetingTable;
