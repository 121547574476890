import React from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { Link } from 'react-router-dom';
import JsBarcode from 'jsbarcode';

const columns = [
  {
    accessor: 'barcode',
    Header: 'Bin Barcode'
  },
  {
    accessor: 'type',
    Header: 'Type'
  },
  {
    accessor: 'status',
    Header: 'Status'
  },
  {
    accessor: 'locationBarcode',
    Header: 'Location'
  },
  {
    accessor: 'action',
    Header: 'Action',
    Cell: rowData => {
      const { id, barcode, locationBarcode } = rowData.row.original;
      const printLabel = () => {
        const printWindow = window.open('', '', 'width=700,height=500');
        printWindow.document.write('<html><head><title>Print Label</title>');
        printWindow.document.write(`
          <style>
          #sub { text-align: center; font-size: 30px; font-weight: bold; }
           @page {
                      size: 700px 500px; 
                      margin: 0; 
                  }
                  body {
                      margin: 0;
                      padding: 0;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      text-align: center; 
                      box-sizing: border-box;
                  }
                  svg {
                      width: auto;
                      height: 280px; 
                      margin: 0 auto; 
                  }
              @media print {
                  @page {
                      size: 700px 500px; 
                      margin: 0; 
                  }
                  #sub { text-align: center; font-size: 30px; font-weight: bold; }
                  body {
                      margin: 0;
                      padding: 0;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      text-align: center; 
                      box-sizing: border-box;
                  }
                  svg {
                      width: auto;
                      height: 280px; 
                      margin: 0 auto; 
                  }
              }
          </style>
      `);

        printWindow.document.write('</head><body>');
        printWindow.document.write(
          `<div style="display: flex; flex-direction: column; align-items: center;">`
        );
        printWindow.document.write(`<svg id="barcode"></svg>`);
        printWindow.document.write(`<div id="sub">${locationBarcode}</div>`);
        printWindow.document.write('</div></body></html>');
        printWindow.document.close();

        printWindow.onload = function () {
          JsBarcode(printWindow.document.getElementById('barcode'), barcode, {
            format: 'CODE128',
            width: 2,
            height: 100,
            displayValue: true,
            fontOptions: 'bold',
            fontSize: 18,
            textMargin: 5
          });

          printWindow.print();
        };

        printWindow.onafterprint = function () {
          printWindow.close();
        };
      };

      return (
        <div>
          <Link to={`/stock-location/bin-management/bin-manager?id=${id}`}>
            Edit
          </Link>
          <label
            onClick={printLabel}
            style={{ display: 'block', color: 'blue', cursor: 'pointer' }}
          >
            Print label
          </label>
        </div>
      );
    }
  }
];
const BinTable = ({
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage
}) => {
  return (
    <>
      <PartialTable
        data={data}
        columns={columns}
        paging={paging}
        total={total}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        // view={'/stock-location/bin-management/bin-manager?id='}
      />
    </>
  );
};

BinTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func
};

export default BinTable;
